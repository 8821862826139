<template>
	<div id="content">
		<div class="box">
			<CustomTable
				ref="imported_horse_table"
				id_table="imported_horse"
				:items="horses"
				:busy.sync="table_busy"
				primaryKey="horse_id"
				:hrefsRoutes="config_table_hrefs"
				:hide_if_empty="true"
				media-field="media_filename"
				:limit="limitHorses"
			/>

		</div>

		<b-modal ref="modalPension" hide-footer>
			<template v-slot:modal-title>
				<template>
					{{ $t("horse.ajouter_pension") }}
				</template>
			</template>
			
			<PensionAjout
				ref="PensionAjout"
				:horse_ids="horse_ids"
				:add-or-edit-ready="add_ready"
			/>

			<b-button class="mt-3 btn-block rounded-pill" block @click.prevent="checkForm" variant="primary"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }}</b-button>
		</b-modal>
	</div>
</template>

<script type="text/javascript">
import ActesMixin from "@/mixins/Actes.js"
import Horse from '@/mixins/Horse.js'
import Model from "@/mixins/Model.js"
import Navigation from "@/mixins/Navigation.js"

export default {
	name: "ListingImportedHorses",
	mixins: [ActesMixin, Horse, Model, Navigation],
    props: ['horses'],
	data () {
		return {
			items: null,
			limitHorses: 0,
			horse_ids: null,
			processing: false,
			add_ready: false,
			table_busy: false,
			config_table_hrefs: {
				'horse_nom': {
					routeUniqueName: 'horseFiche',
					routeName: 'horseFiche',
					params: {
						horse_id: 'horse_id'
					}
				}
			},

			events_tab: {
				'TableAction::goToAddPension': (horse_ids) => {
					this.openModalAddPension(horse_ids)
				},
			},
		}
	},
	created() {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.limitHorses = this.getUserHorseLimit()
		},
		openModalAddPension(horse_ids) {
			this.horse_ids = horse_ids
			this.add_ready = true
			this.$refs.modalPension.show()
			
		},
		async checkForm() {
			if(!this.processing) {
				this.processing = true
				let result = await this.$refs.PensionAjout.checkForm()
				if(result) {
					this.$refs.modalPension.hide()
				}

				this.processing = false
			}
		},
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable : () => import('GroomyRoot/components/Table/CustomTable'),
		ModalEditQualification: () => import('@/components/Contract/ModalEditQualification'),
		PensionAjout : () => import('@/components/Horse/PensionAjout'),
	}
}
</script>
